export const encrypt_data = (content) => {
   var CryptoJS = require("crypto-js");
   var iv = CryptoJS.enc.Utf8.parse("5183666c72eec9e4");
   var key = CryptoJS.enc.Utf8.parse("7c745ac8d499b58aa9709e107ed342fe");

   var encrypted = CryptoJS.AES.encrypt(JSON.stringify(content), key, { iv });

   return encrypted.toString();
};

export const decrypt_data = (content) => {
   var CryptoJS = require("crypto-js");
   var iv = CryptoJS.enc.Utf8.parse("5183666c72eec9e4");
   var key = CryptoJS.enc.Utf8.parse("7c745ac8d499b58aa9709e107ed342fe");

   var decrypted = CryptoJS.AES.decrypt(content, key, { iv }).toString(CryptoJS.enc.Utf8);

   return JSON.parse(decrypted);
};