import React from "react";
import useString from "./useString";

const useUrl = () => {
   const str = useString();
   const url = {
      deviceDetail: "/device-detail/:id",
      getDeviceDetail: function (id) {
         return this.deviceDetail.replace(":id", id);
      },
   };

   return url;
};

export default useUrl;
