import React from "react";

export default function PlacehoderCard() {
   return (
      <div className="placeholder_device_detail mt-5">
         <div className="container-xl">
            <div className="row">
               <div className="col-sm-6 col-md-4 col-lg-4">
                  <div className="card placeholder-glow">
                     <div className="ratio ratio-21x9 card-img-top placeholder" />
                     <div className="card-body">
                        <div className="placeholder col-9 mb-3" />
                        <div className="placeholder placeholder-xs col-10" />
                        <div className="placeholder placeholder-xs col-11" />
                        <div className="mt-3">
                           <a
                              href="#"
                              tabIndex={-1}
                              className="btn btn-primary disabled placeholder col-4"
                              aria-hidden="true"
                           />
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-sm-6 col-md-8 col-lg-8">
                  <div className="card placeholder-glow">
                     <div className="card-body">
                        <div className="placeholder col-6 mb-4 mt-2" />
                        <div className="placeholder placeholder-xs col-10 mb-3" />
                        <div className="placeholder placeholder-xs col-12 mb-3" />
                        <div className="placeholder placeholder-xs col-10 mb-3" />
                        <div className="mt-3">
                           <a
                              href="#"
                              tabIndex={-1}
                              className="btn btn-primary disabled placeholder col-4"
                              aria-hidden="true"
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
