import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import icon from "../../Assets/img/img/google.png";
import { Link } from "react-router-dom";
import "./Auth.scss";
import clsx from "clsx";
import useClient from "../../Services/Hooks/useClient";
import { useDispatch } from "react-redux";
import { getUser } from "./authSlice";
import BtnLoading from "../../Components/BtnLoading/BtnLoading";
import { encrypt_data, decrypt_data } from "../../Services/Helpers/Helpers";

function Login(props) {
   const client = useClient();
   const dispatch = useDispatch();

   const [form, setForm] = useState({
      email: "",
      password: "",
   });

   const [isLoading, setLoading] = useState(false);

   const [isError, setError] = useState({
      status: false,
      message: "",
   });

   const [validEmail, setValidEmail] = useState(true);

   const [validPassword, setValidPassword] = useState(true);

   const [statusLogin, setStatusLogin] = useState(true);

   const [showPassword, setShowPassword] = useState(false);

   const isEmail = (email) => {
      const pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      return pattern.test(email);
   };

   const isPasswordStrength = (password) => {
      const pattern =
         /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%])[A-Za-z\d@#$%]{8,}$/;
      return pattern.test(password);
   };

   const handleChangeValue = (e) => {
      const data = { ...form };
      data[e.target.name] = e.target.value;
      setForm(data);

      if (data.email !== "") {
         const status = isEmail(data.email);
         setValidEmail(status);
      } else {
         setValidEmail(true);
      }

      if (data.password.length < 6 && data.password !== "") {
         setValidPassword(false);
      } else {
         setValidPassword(true);
      }

      if (data.email !== "" && data.password.length > 5) {
         setStatusLogin(false);
      } else {
         setStatusLogin(true);
      }
   };

   const handleLogin = (e) => {
      e.preventDefault();
      const { email, password } = form;

      const data = {
         email: email,
         password: password,
      };

      // encrypt_data - Mã hoá dữ liệu trước khi POST
      postLogin({
         data: encrypt_data(data),
      });
   };

   const postLogin = async (data) => {
      setLoading(true);
      const res = await client.post(client.login, data);
      if (res.response.ok) {
         // decrypt_data - Giải mã dữ liệu nhận được
         res.data = decrypt_data(res.data);
         const dataLogin = {
            token: res.data.data.token,
            isAuth: true,
         };
         sessionStorage.setItem("login", JSON.stringify(dataLogin));
         dispatch(getUser());
      } else {
         setLoading(false);
         const data = decrypt_data(res.data);
         setError({
            status: true,
            message: data.message,
         });
      }
   };

   const handleChecked = (e) => {
      if (e.target.checked) {
         setShowPassword(true);
      } else {
         setShowPassword(false);
      }
   };

   return (
      <>
         <div className="card card-md">
            <div className="card-body">
               <h2 className="card-title text-center mb-4">
                  Login to your account
               </h2>
               {isError.status ? (
                  <div
                     className="alert alert-important alert-danger"
                     role="alert"
                  >
                     <div className="d-flex">
                        <div className="d-flex justify-content-center align-items-center">
                           <i className="ti ti-alert-triangle icon alert-icon"></i>
                        </div>
                        <div>{isError.message}</div>
                     </div>
                  </div>
               ) : null}
               <form onSubmit={handleLogin}>
                  <div className="mb-3">
                     <label className="form-label">Email address</label>
                     <input
                        type="email"
                        name="email"
                        className={clsx(
                           "form-control",
                           !validEmail
                              ? "is-invalid"
                              : form.email !== ""
                              ? "is-valid"
                              : null
                        )}
                        placeholder="your@email.com"
                        onChange={handleChangeValue}
                        // value="quanemulator@gmail.com"
                     />
                  </div>
                  <div className="mb-1">
                     <label className="form-label">
                        Password
                        <span className="form-label-description fz">
                           <Link to="/forgot-password">I forgot password</Link>
                        </span>
                     </label>
                     <div className="input-groupp">
                        <input
                           type={showPassword ? "text" : "password"}
                           name="password"
                           className={clsx(
                              "form-control",
                              !validPassword
                                 ? "is-invalid"
                                 : form.password !== ""
                                 ? "is-valid"
                                 : null
                           )}
                           placeholder="Your password"
                           onChange={handleChangeValue}
                           // value="123456"
                        />
                     </div>
                  </div>
                  <small className="">* At least 6 characters *</small>
                  <div className="mb-3 mt-2">
                     <label className="form-check">
                        <input
                           type="checkbox"
                           className="form-check-input"
                           onChange={handleChecked}
                        />
                        <span className="form-check-label">Show password.</span>
                     </label>
                  </div>
                  <div className="form-footer">
                     {isLoading ? (
                        <BtnLoading />
                     ) : (
                        <button
                           type="submit"
                           className={clsx(
                              "btn btn-primary w-100",
                              statusLogin ? "disabled" : null
                           )}
                        >
                           Sign in
                        </button>
                     )}
                  </div>
               </form>
            </div>
            <div className="hr-text">or</div>
            <div className="card-body">
               <div className="row">
                  {/* <div className="col">
                     <a href="#" className="btn w-100 icon-facebook">
                        <i
                           className="fa-brands fa-facebook"
                           style={{ color: "#1877f2" }}
                        ></i>
                        <span>Login with Facebook</span>
                     </a>
                  </div> */}
                  <div className="col">
                     <a href="#" className="btn w-100 icon-google">
                        <img src={icon} alt="" width={15} height={15} />
                        <span>Login with Google</span>
                     </a>
                  </div>
               </div>
            </div>
         </div>
         {/* <div className="text-center text-muted mt-3">
            Don't have account yet? <Link to="/register">Sign up</Link>
         </div> */}
      </>
   );
}

Login.propTypes = {};

export default Login;
