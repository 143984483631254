import React from "react";
import ModalLockLocate from "./ModalLockLocate";
import ModalMugshot from "./ModalMugshot";
import ModalAlarm from "./ModalAlarm";
import ModalWipeData from "./ModalWipeData";

export default function OpenModal({ idModal, setShowModal, deviceId, token }) {
   return (
      <>
         {idModal === 1 && (
            <ModalLockLocate
               deviceId={deviceId}
               token={token}
               setShowModal={setShowModal}
            />
         )}
         {idModal === 2 && (
            <ModalMugshot
               deviceId={deviceId}
               token={token}
               setShowModal={setShowModal}
            />
         )}
         {idModal === 3 && (
            <ModalAlarm
               deviceId={deviceId}
               token={token}
               setShowModal={setShowModal}
            />
         )}
         {idModal === 4 && (
            <ModalWipeData
               deviceId={deviceId}
               token={token}
               setShowModal={setShowModal}
            />
         )}
      </>
   );
}
