import { Route } from "react-router-dom";
import Home from "../Pages/Home/Home";
import DeviceDetail from "../Pages/Devices/DeviceDetail";

export const publicRoutes = (
   <>
      <Route path="/" element={<Home />} end/>
      <Route path="/device-detail/:id" element={<DeviceDetail />} />
   </>
);
