import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./Services/Redux/Store";
import "./Assets/css/tabler.scss";
import "./Assets/css/tabler-flags.min.scss";
import "./Assets/css/tabler-payments.min.scss";
import "./Assets/css/tabler-vendors.min.scss";
import "./Assets/css/demo.min.scss";

// import { I18nextProvider } from "react-i18next";
// import i18n from "./Components/Translation/i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
   <React.StrictMode>
      <BrowserRouter>
         <Provider store={store}>
            <App />
         </Provider>
      </BrowserRouter>
   </React.StrictMode>
);

reportWebVitals();
