import React from "react";

export default function ResponseError() {
   return (
      <div className="notify error">
         <i className="ti ti-alert-triangle icon icon-error"></i>
         <div className="notify_decription">
            <strong style={{ color: "#D63939" }}>Errors request !</strong>
            <p>We keep the results for 30 days.</p>
         </div>
      </div>
   );
}
