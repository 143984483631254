import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import clsx from "clsx";
import useClient from "../../Services/Hooks/useClient";
import { useDispatch } from "react-redux";
import BtnLoading from "../../Components/BtnLoading/BtnLoading";
import { encrypt_data, decrypt_data } from "../../Services/Helpers/Helpers";
import iconCheck from "../../Assets/img/img/check.png";

function Register(props) {
   const client = useClient();
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const [form, setForm] = useState({
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
   });

   const [isLoading, setLoading] = useState(false);

   const [isError, setError] = useState({
      status: false,
      message: "",
   });

   const [success, setSuccess] = useState(false);

   const [validName, setValidName] = useState(true);

   const [validEmail, setValidEmail] = useState(true);

   const [validPassword, setValidPassword] = useState(true);

   const [validConfirmPassword, setValidConfirmPassword] = useState(true);

   const [statusRegister, setStatusRegister] = useState(true);

   const [showPassword, setShowPassword] = useState(false);

   const isEmail = (email) => {
      const pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      return pattern.test(email);
   };

   const handleChangeValue = (e) => {
      const data = { ...form };
      data[e.target.name] = e.target.value.trim();
      setForm(data);

      if (data.name.length <= 2 && data.name !== "") {
         setValidName(false);
      } else {
         setValidName(true);
      }

      if (data.email !== "") {
         const status = isEmail(data.email);
         setValidEmail(status);
      } else {
         setValidEmail(true);
      }

      if (data.password.length < 6 && data.password !== "") {
         setValidPassword(false);
      } else {
         setValidPassword(true);
      }

      if (
         data.confirmPassword.length < 6 &&
         data.confirmPassword !== data.password &&
         data.confirmPassword !== ""
      ) {
         setValidConfirmPassword(false);
      } else {
         setValidConfirmPassword(true);
      }

      if (
         data.name &&
         data.email &&
         data.password &&
         data.confirmPassword !== ""
      ) {
         setStatusRegister(false);
      } else {
         setStatusRegister(true);
      }
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      const { name, email, confirmPassword } = form;

      const data = {
         name: name,
         email: email,
         password: confirmPassword,
      };

      registerUser({
         data: encrypt_data(data),
      });
   };

   const registerUser = async (data) => {
      setLoading(true);
      const res = await client.post(client.register, data);
      if (res.response.ok) {
         res.data = decrypt_data(res.data);
         const dataLogin = {
            token: res.data.data.token,
         };
         sessionStorage.setItem("login", JSON.stringify(dataLogin));
         setSuccess(true);
      } else {
         setLoading(false);
         const data = decrypt_data(res.data);
         setError({
            status: true,
            message: data.message,
         });
      }
   };

   const handleChecked = (e) => {
      if (e.target.checked) {
         setShowPassword(true);
      } else {
         setShowPassword(false);
      }
   };

   return (
      <>
         {success ? (
            <div className="card card-md">
               <div className="card-body">
                  <div className="card-icon mb-4">
                     <img src={iconCheck} alt="" />
                  </div>
                  <p className="text-muted text-center mb-5">
                     Thanks for signing up. Check your mailbox to verify your
                     account.
                  </p>
                  <div className="form-footer">
                     <a
                        className="btn btn-success w-100"
                        href="https://mail.google.com/mail/u/1/#inbox"
                        target="_blank"
                     >
                        Open your email
                     </a>
                  </div>
               </div>
            </div>
         ) : (
            <>
               <form className="card card-md" onSubmit={handleSubmit}>
                  <div className="card-body">
                     <h2 className="card-title text-center mb-4">
                        Create new account
                     </h2>
                     {isError.status ? (
                        <div
                           className="alert alert-important alert-danger"
                           role="alert"
                        >
                           <div className="d-flex">
                              <div className="d-flex justify-content-center align-items-center">
                                 <i className="ti ti-alert-triangle icon alert-icon"></i>
                              </div>
                              <div>{isError.message}</div>
                           </div>
                        </div>
                     ) : null}
                     <div className="mb-3">
                        <label className="form-label">Name</label>
                        <input
                           type="text"
                           name="name"
                           className={clsx(
                              "form-control",
                              !validName
                                 ? "is-invalid"
                                 : form.name !== ""
                                 ? "is-valid"
                                 : null
                           )}
                           placeholder="Enter name"
                           onChange={handleChangeValue}
                        />
                     </div>
                     <div className="mb-3">
                        <label className="form-label">Email address</label>
                        <input
                           type="email"
                           name="email"
                           className={clsx(
                              "form-control",
                              !validEmail
                                 ? "is-invalid"
                                 : form.email !== ""
                                 ? "is-valid"
                                 : null
                           )}
                           placeholder="your@email.com"
                           onChange={handleChangeValue}
                        />
                     </div>
                     <div className="mb-3">
                        <label className="form-label">Password</label>
                        <div className="input-groupp">
                           <input
                              type={showPassword ? "text" : "password"}
                              name="password"
                              className={clsx(
                                 "form-control",
                                 !validPassword
                                    ? "is-invalid"
                                    : form.password !== ""
                                    ? "is-valid"
                                    : null
                              )}
                              placeholder="Your password"
                              onChange={handleChangeValue}
                           />
                        </div>
                     </div>
                     <div className="mb-2">
                        <label className="form-label">Confirm password</label>
                        <div className="input-groupp">
                           <input
                              type={showPassword ? "text" : "password"}
                              name="confirmPassword"
                              className={clsx(
                                 "form-control",
                                 !validConfirmPassword
                                    ? "is-invalid"
                                    : form.confirmPassword == ""
                                    ? null
                                    : form.confirmPassword === form.password
                                    ? "is-valid"
                                    : "is-invalid"
                              )}
                              placeholder="Confirm password"
                              onChange={handleChangeValue}
                           />
                        </div>
                     </div>
                     <small className="">* At least 6 characters *</small>
                     <div className="mb-3 mt-2">
                        <label className="form-check">
                           <input
                              type="checkbox"
                              className="form-check-input"
                              onChange={handleChecked}
                           />
                           <span className="form-check-label">
                              Show password.
                           </span>
                        </label>
                     </div>
                     <div className="form-footer">
                        {isLoading ? (
                           <BtnLoading />
                        ) : (
                           <button
                              type="submit"
                              className={clsx(
                                 "btn btn-primary w-100",
                                 statusRegister ? "disabled" : null
                              )}
                           >
                              Create new account
                           </button>
                        )}
                     </div>
                  </div>
               </form>
               <div className="text-center text-muted mt-3">
                  Already have account? <Link to="/">Sign in</Link>
               </div>
            </>
         )}
      </>
   );
}

Register.propTypes = {};

export default Register;
