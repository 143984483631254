import React, { useState } from "react";
import PropTypes from "prop-types";
import RouteCore from "../../Services/Routes/RouteCore";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

function Main(props) {

   return (
      <>
         <div className="page">
            <Header />
            <div className="page-wrapper">
               <RouteCore />
            </div>
            <Footer />
         </div>
      </>
   );
}

Main.propTypes = {};

export default Main;
