import React, { useState } from "react";
import PropTypes from "prop-types";
import imgDevices from "../../Assets/img/img/pngegg.png";
import iconConnect from "../../Assets/img/img/wifi (1).svg";
import iconDisconnect from "../../Assets/img/img/disconnect.svg";
import protectsvg from "../../Assets/img/img/Protect.svg";
import passwordsvg from "../../Assets/img/img/Password.svg";
import iconCheck from "../../Assets/img/img/check (1).png";
import iconLock from "../../Assets/img/img/lock-svgrepo-com.svg";
import iconAlarm from "../../Assets/img/img/alarm-warning-svgrepo-com.svg";
import iconCam from "../../Assets/img/img/camera-material-7-svgrepo-com.svg";
import iconData from "../../Assets/img/img/data-economy-finance-2-svgrepo-com.svg";
import { useNavigate } from "react-router-dom";
import Pagination from "../Pagination/Pagination";
import useUrl from "../../Services/Hooks/useUrl";

function DevicesListsDetail(props) {
   const navigate = useNavigate();
   const url = useUrl();
   const { devicesLists } = props;
   const [currentPage, setCurrentPage] = useState(1);
   const itemPerPage = 3;
   const propsCurrentPage = {
      currentPage,
      setCurrentPage,
   };

   const navigateDeviceDetail = (id) => {
      navigate(url.getDeviceDetail(id));
   };

   const renderDevicesListDetail = () => {
      let start = (currentPage - 1) * itemPerPage;
      let end = currentPage * itemPerPage;
      let jsx = [];
      for (let index in devicesLists) {
         if (index >= start && index < end) {
            const { deviceAlias, deviceId, deviceType, osType, _id, isOnline } =
               devicesLists[index];
            const item = (
               <div className="row mb-3" key={_id}>
                  <div className="col-sm-12 col-lg-12">
                     <div className="row device-extended">
                        <div className="col-md-4 col-lg-4 set-pdr">
                           <div className="device-extended_card">
                              <div className="card">
                                 <div className="card-body">
                                    <div className="card-header">
                                       <div className="u_device">
                                          <div className="u_device-img">
                                             <img
                                                src={imgDevices}
                                                alt="device"
                                             />
                                             <div className="u_device-icon">
                                                <img
                                                   src={
                                                      isOnline
                                                         ? iconConnect
                                                         : iconDisconnect
                                                   }
                                                   alt="icon"
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="card-content justify-content-center flex-column">
                                       <p className="system_name mb-0">
                                          {deviceType} &nbsp; {osType}
                                       </p>
                                       <h1 className="device_name">
                                          {deviceAlias} (Mobile)
                                       </h1>
                                    </div>
                                    <div className="device__icon mt-3">
                                       <img
                                          src={protectsvg}
                                          className="me-2"
                                          alt="blocked"
                                       />
                                       <img src={passwordsvg} alt="blocked" />
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-8 col-lg-8">
                           <div className="row device-extended_content">
                              <div className="col-md-6 col-lg-6 seperate">
                                 <div className="device-extended_panel">
                                    <div className="overview_list">
                                       <div className="overview_title">
                                          <p>Overview</p>
                                       </div>
                                       <ul className="overview_content">
                                          <li className="overview_content-item">
                                             <img
                                                src={iconCheck}
                                                alt=""
                                                width={22}
                                                height={22}
                                             />
                                             Where is my devices is turned on.
                                          </li>
                                          <li className="overview_content-item">
                                             <img
                                                src={iconCheck}
                                                alt=""
                                                width={22}
                                                height={22}
                                             />
                                             All subcriptions are in effect.
                                          </li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-md-6 col-lg-6 seperate">
                                 <div className="device-extended_panel">
                                    <div className="feature_list">
                                       <div className="feature_title">
                                          <p>Where is my devices</p>
                                       </div>
                                       <ul className="feature_content">
                                          <li className="feature_content-item">
                                             <img
                                                src={iconLock}
                                                alt=""
                                                width={30}
                                                height={30}
                                             />
                                             Lock & Locate
                                          </li>
                                          <li className="feature_content-item">
                                             <img
                                                src={iconAlarm}
                                                alt=""
                                                width={30}
                                                height={30}
                                             />
                                             Alarm
                                          </li>
                                          <li className="feature_content-item">
                                             <img
                                                src={iconCam}
                                                alt=""
                                                width={30}
                                                height={30}
                                             />
                                             Mugshot
                                          </li>
                                          <li className="feature_content-item">
                                             <img
                                                src={iconData}
                                                alt=""
                                                width={30}
                                                height={30}
                                             />
                                             Data Wipe
                                          </li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                              <span
                                 onClick={(e) => {
                                    e.preventDefault();
                                    navigateDeviceDetail(deviceId);
                                 }}
                                 className="material-symbols-outlined arrow-icon"
                              >
                                 arrow_forward_ios
                              </span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            );
            jsx.push(item);
         }
      }
      return jsx;
   };

   return (
      <>
         {renderDevicesListDetail()}
         <Pagination
            devicesLists={devicesLists}
            propsCurrentPage={propsCurrentPage}
         />
      </>
   );
}

DevicesListsDetail.propTypes = {};

export default DevicesListsDetail;
