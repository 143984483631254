import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import iconData from "../../../../Assets/img/img/data-wipe-icon.svg";
import ResponseDefault from "../ResponseStatus/ResponseDefault";
import ResponsePendding from "../ResponseStatus/ResponsePendding";
import ResponseError from "../ResponseStatus/ResponseError";
import ResponseSuccess from "../ResponseStatus/ResponseSuccess";

function WipeDataEvent(props) {
   const { status, onModal, onEventDetail } = props;
   const [loadingResponse, setLoadingResponse] = useState(false);
   const [statusResponse, setStatusResponse] = useState(null);
   return (
      <>
         <div className="mydevice__item">
            <div className="mydevice__item-tile_control">
               <div className="mydevice__item-tile_logo">
                  <img src={iconData} alt="" />
               </div>
               <div className="mydevice__item-tile_decription">
                  <div className="title">
                     <b>Data Wipe</b>
                     <span
                        onClick={(e) => {
                           e.preventDefault();
                           onEventDetail(4);
                        }}
                        className="material-symbols-outlined icon-info"
                     >
                        info
                     </span>
                  </div>
                  <div className="decription">
                     <p>
                        Remotely perform a factory reset on your device,
                        including the memory card.
                     </p>
                  </div>
                  <div className="notify">
                     <small>
                        We keep no more than 30 results for 30 days for all
                        commands.
                     </small>
                  </div>
               </div>
               <div className="mydevice__item-tile_btn">
                  <button
                     onClick={(e) => {
                        e.preventDefault();
                        onModal(4);
                     }}
                     className={clsx(
                        "btn btn-primary w-100",
                        status ? null : "disabled"
                     )}
                  >
                     Data Wipe
                  </button>
               </div>
            </div>
            {!status && (
               <div className="mydevice__item-tile_history">
                  {!loadingResponse ? (
                     statusResponse === null ? (
                        <ResponseDefault />
                     ) : statusResponse ? (
                        <ResponseSuccess />
                     ) : (
                        <ResponseError />
                     )
                  ) : (
                     <ResponsePendding />
                  )}
               </div>
            )}
         </div>
      </>
   );
}

WipeDataEvent.propTypes = {};

export default WipeDataEvent;
