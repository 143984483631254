import React, { useEffect } from "react";
import iconEmail from "../../Assets/img/img/email.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "bootstrap";
import useClient from "../../Services/Hooks/useClient";
import { useState } from "react";
import { encrypt_data, decrypt_data } from "../../Services/Helpers/Helpers";
import iconFireword from "../../Assets/img/img/fireworks.png";

export default function VerifyEmail() {
   const { token } = useParams();
   const navigate = useNavigate();
   const client = useClient();
   const [statusData, setStatus] = useState({
      status: false,
      message: "",
   });

   const verifyAccount = async (data) => {
      const res = await client.post(client.verify, data);
      if (res.response.ok) {
         const deData = decrypt_data(res.data);
         setStatus({
            status: true,
            message: deData.message,
         });
      }
   };

   return (
      <>
         {!statusData.status ? (
            <form className="card card-md">
               <div className="card-body">
                  <div className="card-icon mb-4">
                     <img src={iconEmail} alt="" />
                  </div>
                  <p className="text-muted text-center mb-5">
                     Thanks for signing up. To verify your email address, please
                     click the button below.
                  </p>
                  <div className="form-footer">
                     <button
                        onClick={(e) => {
                           e.preventDefault();
                           verifyAccount({
                              data: encrypt_data({
                                 token: token,
                              }),
                           });
                        }}
                        className="btn btn-success w-100"
                     >
                        Confirm your email address
                     </button>
                  </div>
               </div>
            </form>
         ) : (
            <div className="card card-md">
               <div className="card-body">
                  <h2 className="card-title mb-4">Done !</h2>
                  <div className="card-icon mb-4">
                     <img src={iconFireword} alt="" />
                  </div>
                  <div
                     className="alert alert-success alert-dismissible"
                     role="alert"
                  >
                     <div className="d-flex">
                        <div>
                           <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon alert-icon"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              strokeWidth={2}
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                           >
                              <path
                                 stroke="none"
                                 d="M0 0h24v24H0z"
                                 fill="none"
                              />
                              <path d="M5 12l5 5l10 -10" />
                           </svg>
                        </div>
                        <div>
                           <h4 className="alert-title">{statusData.message}</h4>
                        </div>
                     </div>
                  </div>
                  <div className="form-footer">
                     <Link to="/" className="btn btn-success w-100">
                        Back to login
                     </Link>
                  </div>
               </div>
            </div>
         )}
      </>
   );
}
