import React from "react";
import PropTypes from "prop-types";
import Devices from "../Devices/Devices";
import Footer from "../../Layouts/Footer/Footer";
import "./Home.scss"

function Home(props) {
   return (
      <>
         <div className="page-body">
            <div className="container-xl">
               <Devices />
            </div>
         </div>
      </>
   );
}

Home.propTypes = {};

export default Home;
