import React from "react";

export default function ResponseSuccess() {
   return (
      <div className="notify success">
         <i className="ti ti-circle-check icon icon-check"></i>
         <div className="notify_decription">
            <strong style={{color: "#2FB344"}}>Successful request !</strong>
            <p>We keep the results for 30 days.</p>
         </div>
      </div>
   );
}
