import { Route } from "react-router-dom";
import Login from "../Pages/Auth/Login";
import Register from "../Pages/Auth/Register";
import NewPassword from "../Pages/Auth/NewPassword";
import ForgotPassword from "../Pages/Auth/ForgotPassword";
import VerifyEmail from "../Pages/Auth/VerifyEmail";
import Verified from "../Pages/Auth/Verified";

export const authRoutes = (
   <>
      <Route path="/" element={<Login />} end />
      <Route path="/register" element={<Register />} />
      <Route path="/new-password" element={<NewPassword />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/verify/:token" element={<VerifyEmail />} />
      <Route path="/verified" element={<Verified />} />
   </>
);
