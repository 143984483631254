import React from 'react'
import "./LoadingPage.scss"

export default function LoadingPage() {
  return (
    <div className='loading_page'>
        <div className="spinner-border text-orange me-3 set-spinner-border" role="status"></div>
        <span className='loading'>Loading...</span>
    </div>
  )
}
