import React from "react";
import iconFireword from "../../Assets/img/img/fireworks.png";
import iconError from "../../Assets/img/img/error.png";
import { Link } from "react-router-dom";


export default function Verified() {
   return (
      <>
         <div className="card card-md">
            <div className="card-body">
               <h2 className="card-title mb-4">Done !</h2>
               <div className="card-icon mb-4">
                  <img src={iconFireword} alt="" />
               </div>
               <div
                  className="alert alert-success alert-dismissible"
                  role="alert"
               >
                  <div className="d-flex">
                     <div>

                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           className="icon alert-icon"
                           width={24}
                           height={24}
                           viewBox="0 0 24 24"
                           strokeWidth={2}
                           stroke="currentColor"
                           fill="none"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                        >
                           <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                           <path d="M5 12l5 5l10 -10" />
                        </svg>
                     </div>
                     <div>
                        <h4 className="alert-title">Success! Your email has been verified.</h4>
                     </div>
                  </div>
               </div>

               <div className="form-footer">
                  <Link to="/" className="btn btn-success w-100">
                     Back to login
                  </Link>
               </div>
            </div>
         </div>
         {/* <div className="card card-md">
            <div className="card-body">
               <h2 className="card-title mb-4">Error !</h2>
               <div className="card-icon mb-4">
                  <img src={iconError} alt="" />
               </div>
               <div
                  className="alert alert-danger alert-dismissible"
                  role="alert"
               >
                  <div className="d-flex">
                     <div>
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           className="icon alert-icon"
                           width={24}
                           height={24}
                           viewBox="0 0 24 24"
                           strokeWidth={2}
                           stroke="currentColor"
                           fill="none"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                        >
                           <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                           <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                           <path d="M12 8l0 4" />
                           <path d="M12 16l.01 0" />
                        </svg>
                     </div>

                     <div>
                        <h4 className="alert-title">
                           Failure! Please try again.
                        </h4>
                     </div>
                  </div>
               </div>

               <div className="form-footer">
                  <Link to="/" className="btn btn-danger w-100">
                     Back 
                  </Link>
               </div>
            </div>
         </div> */}
      </>
   );
}
