import React from "react";
import { Routes, Route } from "react-router-dom";
import { publicRoutes } from "../../Routes/publicRoutes";
import Error404 from "../../Pages/Errors/Error404";

export default function RouteCore() {
   return (
      <Routes>
         {publicRoutes}
         <Route path="*" element={<Error404 />} />
      </Routes>
   );
}
