import React from "react";
import PropTypes from "prop-types";
import AuthRouteCore from "../../Services/Routes/AuthRouteCore";
import logo from "../../Assets/img/img/Frame 2864153.jpg";
import { ToastContainer } from "react-toastify";
import secureLogin from "../../Assets/img/img/undraw_secure_login_pdn4.svg";
import "./Auth.scss";

function Authenticator(props) {
   return (
      <>
         <div className="page page-center">
            <div className="container container-normal py-4">
               <div className="row align-items-center g-4">
                  <div className="col-lg">
                     <div className="container-tight">
                        <div className="text-center mb-2 mb-mb">
                           <a
                              href="."
                              className="navbar-brand navbar-brand-autodark"
                           >
                              <img src={logo} height={80} alt="" className="logo_login" />
                           </a>
                        </div>
                        <AuthRouteCore />
                     </div>
                  </div>
                  <div className="col-lg d-none d-lg-block">
                     <img
                        src={secureLogin}
                        height={300}
                        className="d-block mx-auto"
                        alt=""
                     />
                  </div>

                  <a href="https://sites.google.com/view/msafe-privacy-policy" className="text-center mt-4">By continuing, you agree to Msafe Global's Privacy Policy.</a>
               </div>
            </div>
            <ToastContainer />
         </div>
      </>
   );
}

Authenticator.propTypes = {};

export default Authenticator;
