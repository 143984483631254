import React from "react";
import PropTypes from "prop-types";
import "./Footer.scss";
import logo from "../../Assets/img/img/Frame 2864153.jpg";

function Footer(props) {
   return (
      <footer className="footer footer-transparent d-print-none">
         <div className="container-xl">
            <div className="row text-center align-items-center flex-row-reverse pb-2 border-bottom">
               <div className="col-lg-auto ms-lg-auto">
                  <ul className="list-inline list-inline-dots mb-0">
                     <li className="list-inline-item">
                        <i className="fa-brands fa-facebook"></i>
                     </li>
                     <li className="list-inline-item">
                        <i className="fa-brands fa-instagram"></i>
                     </li>
                     <li className="list-inline-item">
                        <i className="fa-brands fa-youtube"></i>
                     </li>
                     <li className="list-inline-item">
                        <i className="fa-brands fa-linkedin"></i>
                     </li>
                     <li className="list-inline-item">
                        <i className="fa-brands fa-twitter"></i>
                     </li>
                  </ul>
               </div>
               <div className="col-12 col-lg-auto mt-3 mt-lg-0">
                  <img src={logo} className="logo_btech" alt="" width={40} />
               </div>
            </div>
            <div className="row text-center align-items-center flex-row-reverse mt-2">
               <div className="col-lg-auto ms-lg-auto">
                  <ul className="list-inline list-inline-dots mb-0">
                     <li className="list-inline-item">
                        <a href="" className="link-secondary">
                           About us
                        </a>
                     </li>
                     <li className="list-inline-item">
                        <a href="" className="link-secondary">
                           Contacts
                        </a>
                     </li>
                     <li className="list-inline-item">
                        <a href="" className="link-secondary">
                           Help
                        </a>
                     </li>
                     <li className="list-inline-item">
                        <a href="" className="link-secondary">
                           Terms of Use
                        </a>
                     </li>
                  </ul>
               </div>
               <div className="col-12 col-lg-auto mt-3 mt-lg-0">
                  <ul className="list-inline list-inline-dots mb-0">
                     <li className="list-inline-item">
                        Copyright © 2023{"."}
                        {/* <a href="." className="link-secondary">
                           {" "}
                           Bas Antivirus
                        </a> */}
                        . All rights reserved.
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </footer>
   );
}

Footer.propTypes = {};

export default Footer;
