import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import useClient from "../../Services/Hooks/useClient";
import { encrypt_data, decrypt_data } from "../../Services/Helpers/Helpers";

const initialState = {
   userLogin: {},
   isAuth: false,
   isLoading: true,
};

const authSlice = createSlice({
   name: "auth",
   initialState: initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder
      .addCase(getUser.fulfilled, (state, action) => {
         if (action.payload) {
            state.userLogin = action.payload;
            state.isAuth = true;
         } else {
            state.isAuth = false;
         }
         state.isLoading = false;
      })
   },
});

//call api
export const getUser = createAsyncThunk("auth/getUserStatus", async () => {
   const client = useClient();
   if (sessionStorage.getItem("login")) {
      let dataLogin = sessionStorage.getItem("login");
      dataLogin = JSON.parse(dataLogin);
      const { token } = dataLogin;
      const res = await client.get(client.profile, {}, token);

      if (res.response.ok) {
         res.data = decrypt_data(res.data);
         const data = res.data;
         return data;
      }
   }
   return false;
});


export const authReducer = authSlice.reducer;

export const authActions = authSlice.actions;

export const authSelector = (state) => state.auth;
