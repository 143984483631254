import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import imgDevices from "../../Assets/img/img/pngegg.png";
import iconConnect from "../../Assets/img/img/wifi (1).svg";
import iconDisconnect from "../../Assets/img/img/disconnect.svg";
import clsx from "clsx";
import iconCheck from "../../Assets/img/img/check (1).png";
import { useNavigate, useParams } from "react-router-dom";
import useClient from "../../Services/Hooks/useClient";
import { encrypt_data, decrypt_data } from "../../Services/Helpers/Helpers";
import PlacehoderCard from "../../Components/Placehoder/PlacehoderCard";
import LockEvent from "./Component/EventDevice/LockEvent";
import MugshotEvent from "./Component/EventDevice/MugshotEvent";
import AlarmEvent from "./Component/EventDevice/AlarmEvent";
import OpenModal from "../Modal/OpenModal";
import WipeDataEvent from "./Component/EventDevice/WipeDataEvent";
import EventDetail from "./Component/EventDetail/EventDetail";

function DeviceDetail(props) {
   const [showCode, setShowCode] = useState(false);
   const [showModal, setShowModal] = useState(false);
   const [eventDetail, setEventDetail] = useState(0);
   const [idModal, setIdModal] = useState(0);
   const [showFormChangeName, setShowFormChangeName] = useState(false);
   const [nameDevice, setNameDevice] = useState("");
   const [token, setToken] = useState("");
   const [loading, setLoading] = useState(true);
   const [active, setActive] = useState(true);
   const [dataDevices, setDataDevices] = useState([]);
   const navigate = useNavigate();
   const inputRef = useRef(null);
   const client = useClient();
   const { id } = useParams();
   const code = "234234567567";
   const infomation = [
      {
         id: 1,
         title: "Where is my devices is turned on.",
      },
      {
         id: 2,
         title: "All subcriptions are in effect.",
      },
      {
         id: 3,
         title: "Internet Protection is turned on.",
      },
      {
         id: 4,
         title: "Automatic Anti-Virus is turned on.",
      },
      {
         id: 5,
         title: "Databases are up to date.",
      },
   ];

   const listBtn = [
      {
         id: 1,
         name: "Lock & Locate",
         status: dataDevices[0]?.lockAndLocateOn,
      },
      {
         id: 2,
         name: "Mugshot",
         status: dataDevices[0]?.mugshotOn,
      },
      {
         id: 3,
         name: "Alarm",
         status: dataDevices[0]?.alarmOn,
      },
      {
         id: 4,
         name: "Data Wipe",
         status: dataDevices[0]?.wipeOn,
      },
   ];

   useEffect(() => {
      if (sessionStorage.getItem("login")) {
         let dataLogin = sessionStorage.getItem("login");
         dataLogin = JSON.parse(dataLogin);
         const { token } = dataLogin;
         setToken(token);
         getDeviceDetail(id, token);
      }
   }, []);

   const handleShowCode = (e) => {
      e.preventDefault();
      setShowCode(true);
   };

   const handleSetActive = (e) => {
      e.preventDefault();
      if (e.target.innerText === "Where Is My Device") {
         setActive(false);
      } else {
         setActive(true);
      }
   };

   const navigateHome = (e) => {
      e.preventDefault();
      navigate("/");
   };

   const handleChangeValue = (e) => {
      e.preventDefault();
      const name = e.target.value;
      setNameDevice(name);
   };

   const getDeviceDetail = async (id, token) => {
      const res = await client.get(client.devices + `/${id}`, {}, token);
      if (res.response.ok) {
         res.data = decrypt_data(res.data);
         const data = res.data;
         setLoading(false);
         setDataDevices(data.data);
         // console.log(data.data);
         setNameDevice(data.data[0].deviceAlias);
      }
   };

   const handleEditNameDevice = (e) => {
      e.preventDefault();
      setShowFormChangeName(true);
      setTimeout(() => {
         if (inputRef.current) {
            inputRef.current.value = nameDevice;
         }
      }, 1);
   };

   const submitEditNameDevice = (e) => {
      e.preventDefault();
      const data = {
         deviceAlias: nameDevice,
      };
      putNameDevice(id, { data: encrypt_data(data) }, token);
   };

   const putNameDevice = async (id, data, token) => {
      const res = await client.put(client.devices + `/${id}`, data, {}, token);
      if (res.response.ok) {
         await getDeviceDetail(id, token);
         setShowFormChangeName(false);
      }
   };

   const onModal = (id) => {
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = "16px";
      setIdModal(id);
      setShowModal(true);
   };

   const onEventDetail = (id) => {
      setEventDetail(id);
   };

   return (
      <>
         {loading ? (
            <PlacehoderCard />
         ) : (
            <div className="page-body">
               <div className="container-xl">
                  <div className="row">
                     <div className="device_detail-page">
                        <div className="col-sm-12 col-lg-12">
                           <div className="device_detail-menu">
                              <div
                                 onClick={navigateHome}
                                 className="device_detail--item"
                              >
                                 My Bas Antivirus
                              </div>
                              <div className="slash"></div>
                              <div className="device_detail--item">Devices</div>
                              <div className="slash"></div>
                              <div className="device_detail--item">
                                 {nameDevice}&nbsp;(Mobile)
                              </div>
                           </div>
                        </div>
                        <div className="col-sm-12 col-lg-12">
                           <button
                              className="button-back"
                              onClick={navigateHome}
                           >
                              <span className="material-symbols-outlined">
                                 arrow_left
                              </span>
                              All devices
                           </button>
                        </div>
                     </div>
                  </div>
                  <div className="row mt-4">
                     <div className="col-sm-12 col-lg-4">
                        <div className="devices-detail">
                           <div className="device-extended_card">
                              <div className="card">
                                 <div className="card-body">
                                    <div className="card-header">
                                       <div className="u_device">
                                          <div className="u_device-img">
                                             <img
                                                src={imgDevices}
                                                alt="device"
                                             />
                                             <div className="u_device-icon">
                                                <img
                                                   src={
                                                      dataDevices[0]?.isOnline
                                                         ? iconConnect
                                                         : iconDisconnect
                                                   }
                                                   alt="icon"
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="card-content justify-content-center flex-column b-bottom">
                                       <p className="system_name mb-0">
                                          {dataDevices[0]?.deviceType}&nbsp;
                                          {dataDevices[0]?.osType}
                                       </p>
                                       {showFormChangeName ? (
                                          <>
                                             <form
                                                onSubmit={submitEditNameDevice}
                                                className="w-100 mt-2"
                                             >
                                                <input
                                                   type="text"
                                                   ref={inputRef}
                                                   className="form-control"
                                                   onChange={handleChangeValue}
                                                />
                                                <div className="button-group mb-3">
                                                   <button className="btn btn-success">
                                                      Save
                                                   </button>
                                                   <div
                                                      onClick={(e) => {
                                                         e.preventDefault();
                                                         setShowFormChangeName(
                                                            false
                                                         );
                                                      }}
                                                      className="btn btn-primary"
                                                   >
                                                      Cancel
                                                   </div>
                                                </div>
                                             </form>
                                          </>
                                       ) : (
                                          <>
                                             <div className="device_name detail">
                                                <h1>
                                                   {dataDevices[0]?.deviceAlias}{" "}
                                                   (Mobile)
                                                </h1>
                                             </div>
                                             <span
                                                onClick={handleEditNameDevice}
                                                className="material-symbols-outlined edit_name_device mb-3"
                                             >
                                                edit
                                             </span>
                                          </>
                                       )}
                                       {listBtn.map(({ id, name, status }) => {
                                          return (
                                             <button
                                                key={id}
                                                onClick={(e) => {
                                                   e.preventDefault();
                                                   onModal(id);
                                                }}
                                                className={clsx(
                                                   "btn btn-primary btn_detail",
                                                   status ? null : "disabled"
                                                )}
                                             >
                                                {name}
                                             </button>
                                          );
                                       })}

                                       {/* <div className="recovery_code">
                                          <span className="me-2">
                                             Recovery code:
                                          </span>
                                          <span className="material-symbols-outlined icon_help">
                                             help
                                          </span>
                                       </div>
                                       <div className="recovery_code_value mb-2">
                                          <input
                                             className=""
                                             onChange={handleChangeValue}
                                             type={
                                                showCode ? "text" : "password"
                                             }
                                             value={code}
                                          />
                                          {!showCode ? (
                                             <span
                                                onClick={handleShowCode}
                                                className="material-symbols-outlined"
                                             >
                                                visibility
                                             </span>
                                          ) : null}
                                       </div> */}
                                    </div>
                                 </div>
                                 <div className="disconnect_device">
                                    <span>Disconnect device</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-sm-12 col-lg-8">
                        <div className="devices-extended__overview">
                           <div className="overview__header">
                              <ul className="overview_header-menu">
                                 <li
                                    onClick={handleSetActive}
                                    className={clsx(
                                       "overview_header-item",
                                       active ? "active" : null
                                    )}
                                 >
                                    Infomation
                                 </li>
                                 <li
                                    onClick={handleSetActive}
                                    className={clsx(
                                       "overview_header-item",
                                       !active ? "active" : null
                                    )}
                                 >
                                    Where Is My Device
                                 </li>
                              </ul>
                           </div>
                           <div className="overview__content">
                              {active ? (
                                 <div className="overview_content_list">
                                    <ul className="overview_content">
                                       {infomation.map(({ id, title }) => {
                                          return (
                                             <li
                                                key={id}
                                                className="overview_content-item"
                                             >
                                                <img
                                                   src={iconCheck}
                                                   alt=""
                                                   width={22}
                                                   height={22}
                                                />
                                                {title}
                                             </li>
                                          );
                                       })}
                                    </ul>
                                 </div>
                              ) : (
                                 <div className="overview__content_mydevice">
                                    {eventDetail === 0 ? (
                                       <>
                                          <LockEvent
                                             status={
                                                dataDevices[0]?.lockAndLocateOn
                                             }
                                             onModal={onModal}
                                             onEventDetail={onEventDetail}
                                          />
                                          <MugshotEvent
                                             status={dataDevices[0]?.mugshotOn}
                                             onModal={onModal}
                                             onEventDetail={onEventDetail}
                                          />
                                          <AlarmEvent
                                             status={dataDevices[0]?.alarmOn}
                                             onModal={onModal}
                                             onEventDetail={onEventDetail}
                                          />
                                          <WipeDataEvent
                                             status={dataDevices[0]?.wipeOn}
                                             onModal={onModal}
                                             onEventDetail={onEventDetail}
                                          />
                                       </>
                                    ) : (
                                       <EventDetail
                                          eventDetail={eventDetail}
                                          setEventDetail={setEventDetail}
                                       />
                                    )}
                                 </div>
                              )}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         )}
         {showModal && (
            <OpenModal
               idModal={idModal}
               setShowModal={setShowModal}
               deviceId={id}
               token={token}
            />
         )}
      </>
   );
}

DeviceDetail.propTypes = {};

export default DeviceDetail;
