import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Auth.scss";
import { Link } from "react-router-dom";
import iconFireword from "../../Assets/img/img/fireworks.png";
import clsx from "clsx";
import useClient from "../../Services/Hooks/useClient";
import BtnLoading from "../../Components/BtnLoading/BtnLoading";
import { encrypt_data, decrypt_data } from "../../Services/Helpers/Helpers";

function ForgotPassword(props) {
   const client = useClient();

   const [email, setEmail] = useState("");

   const [isLoading, setLoading] = useState(false);

   const [statusRequest, setStatusRequest] = useState({
      status: false,
      message: "",
   });

   const [status, setStatus] = useState({
      status: true,
      message: "",
   });

   const [validEmail, setValidEmail] = useState(true);

   const [statusBtn, setStatusBtn] = useState(true);

   const isEmail = (email) => {
      const pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      return pattern.test(email);
   };

   const handleChangeValue = (e) => {
      let email = e.target.value;
      setEmail(email);
      if (email !== "") {
         const status = isEmail(email);
         setValidEmail(status);
         if (status === true) {
            setStatusBtn(false);
         }
      } else {
         setValidEmail(true);
         setStatusBtn(true);
      }
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      const data = {
         email: email,
      };
      postForgotPassword({ data: encrypt_data(data) });
   };

   const postForgotPassword = async (data) => {
      setLoading(true);
      const res = await client.post(client.forgot_password, data);
      const dataStatus = decrypt_data(res.data);
      if (res.response.ok) {
         console.log(dataStatus);
         setStatus({
            status: false,
            message: dataStatus.message,
         });
      } else {
         setLoading(false);
         setStatusRequest({
            status: true,
            message: dataStatus.message,
         });
      }
   };

   return (
      <>
         {!status.status ? (
            <>
               <div className="card card-md">
                  <div className="card-body">
                     <h2 className="card-title mb-4">Done !</h2>
                     <div className="card-icon mb-4">
                        <img src={iconFireword} alt="" />
                     </div>
                     <div
                        className="alert alert-success alert-dismissible"
                        role="alert"
                     >
                        <div className="d-flex">
                           <div>
                              <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 className="icon alert-icon"
                                 width={24}
                                 height={24}
                                 viewBox="0 0 24 24"
                                 strokeWidth={2}
                                 stroke="currentColor"
                                 fill="none"
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              >
                                 <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                 />
                                 <path d="M5 12l5 5l10 -10" />
                              </svg>
                           </div>
                           <div>
                              <h4 className="alert-title">{status.message}</h4>
                              {/* <div className="text-muted">
                                 Please check your email to reset your password
                                 !
                              </div> */}
                           </div>
                        </div>
                     </div>

                     <div className="form-footer">
                        <a
                           href="https://mail.google.com/mail/u/0/#inbox"
                           className="btn btn-success w-100"
                           target="_blank"
                        >
                           Open your email
                        </a>
                     </div>
                  </div>
               </div>
               <div className="text-center text-muted mt-3">
                  Forget it, <Link to="/">send me back</Link> to the sign in
                  screen.
               </div>
            </>
         ) : (
            <>
               <form className="card card-md" onSubmit={handleSubmit}>
                  <div className="card-body">
                     <h2 className="card-title text-center mb-4">
                        Forgot password
                     </h2>
                     {statusRequest.status ? (
                        <div
                           className="alert alert-important alert-danger"
                           role="alert"
                        >
                           <div className="d-flex">
                              <div className="d-flex justify-content-center align-items-center">
                                 <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon alert-icon"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    strokeWidth={2}
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                 >
                                    <path
                                       stroke="none"
                                       d="M0 0h24v24H0z"
                                       fill="none"
                                    />
                                    <path d="M12 9v2m0 4v.01" />
                                    <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" />
                                 </svg>
                              </div>
                              <div>{statusRequest.message}</div>
                           </div>
                        </div>
                     ) : (
                        <p className="text-muted mb-4">
                           Enter your registered email address below to reset
                           your password.
                        </p>
                     )}
                     <div className="mb-3">
                        <label className="form-label">Email address</label>
                        <input
                           type="email"
                           name="email"
                           className={clsx(
                              "form-control",
                              !validEmail
                                 ? "is-invalid"
                                 : email !== ""
                                 ? "is-valid"
                                 : null
                           )}
                           placeholder="Enter email"
                           onChange={handleChangeValue}
                        />
                     </div>
                     <div className="form-footer">
                        {isLoading ? (
                           <BtnLoading />
                        ) : (
                           <button
                              className={clsx(
                                 "btn btn-primary w-100",
                                 statusBtn ? " disabled" : null
                              )}
                           >
                              <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 className="icon"
                                 width={24}
                                 height={24}
                                 viewBox="0 0 24 24"
                                 strokeWidth={2}
                                 stroke="currentColor"
                                 fill="none"
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              >
                                 <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                 />
                                 <path d="M3 5m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                                 <path d="M3 7l9 6l9 -6" />
                              </svg>
                              Send me new password
                           </button>
                        )}
                     </div>
                  </div>
               </form>
               <div className="text-center text-muted mt-3">
                  Forget it, <Link to="/">send me back</Link> to the sign in
                  screen.
               </div>
            </>
         )}
      </>
   );
}

ForgotPassword.propTypes = {};

export default ForgotPassword;
