import React, { useEffect } from "react";
import "./index.scss";
import imgLocate from "../../../../Assets/img/img/locate_.png";
import imgMugshot from "../../../../Assets/img/img/mugshot__.png";
import imgAlarm from "../../../../Assets/img/img/alarm__.png";
import imgLock from "../../../../Assets/img/img/lock--.png";
import imgMessage from "../../../../Assets/img/img/message__.png";
import imgTutorial1 from "../../../../Assets/img/img/tutorial1.png";
import imgTutorial2 from "../../../../Assets/img/img/tutorial2.png";
import imgTutorial3 from "../../../../Assets/img/img/tutorial3.png";

export default function EventDetail(props) {
   const { eventDetail, setEventDetail } = props;

   const events = [
      {
         id: 1,
         title: "Lock & Locate",
         image1: imgLocate,
         image2: imgLock,
         image3: imgMessage,
         image4: imgTutorial1,
         desc1: "Locates your device on the map.",
         desc2: "You will get an approximate GPS position and coordinates (~200 m) to find your device",
      },
      {
         id: 2,
         title: "Mugshot",
         image1: imgMugshot,
         image2: imgLock,
         image3: imgMessage,
         image4: imgTutorial2,
         desc1: "Secretly takes a photo.",
         desc2: "The frontal camera takes photos of those who tries to unlock your device. View and download the mugshots in your account.",
      },
      {
         id: 3,
         title: "Alarm",
         image1: imgAlarm,
         image2: imgLock,
         image3: imgMessage,
         image4: imgTutorial3,
         desc1: "Turns on a loud siren.",
         desc2: "Enable the alarm remotely. It can’t be turned off without your passcode or fingerprint.",
      },
      {
         id: 4,
      },
   ];

   const dataEvent = events.filter((event) => event.id === eventDetail);

   return (
      <div className="event__detail">
         <button
            onClick={(e) => {
               e.preventDefault();
               setEventDetail(0);
            }}
            type="button"
            className="btn-back"
         >
            <i className="ti ti-chevron-left icon"></i>
            <span>Back</span>
         </button>
         <h2 className="title text-center">{dataEvent[0].title}</h2>
         <div className="list__item">
            <div className="item">
               <div className="image">
                  <img className="item_img" src={dataEvent[0].image1} alt="" />
               </div>
               <div className="item_description">
                  <p>{dataEvent[0].desc1}</p>
                  <p>{dataEvent[0].desc2}</p>
                  <a href="">{dataEvent[0].title}&nbsp;example</a>
               </div>
            </div>
            <div className="item">
               <div className="image">
                  <img className="item_img" src={imgLock} alt="" />
               </div>
               <div className="item_description">
                  <p>Locks the device</p>
                  <p>
                     Your device will be locked automatically when located. Only
                     you will be able to unlock the device.
                  </p>
               </div>
            </div>
            <div className="item">
               <div className="image">
                  <img
                     className="item_img"
                     src={imgMessage}
                     alt=""
                  />
               </div>
               <div className="item_description">
                  <p>Shows a message on the screen.</p>
                  <p>
                     Your lost device may be in good hands. Leave your contacts
                     so your device can be returned.
                  </p>
               </div>
            </div>
         </div>
         <div className="tutorial">
            <h2 className="title">How do I turn off?</h2>
            <img src={dataEvent[0].image4} alt="" />
            <p>
               Open the Basprotect Internet Security for Android app on your
               device and turn off the Lock & Locate feature.
            </p>
         </div>
      </div>
   );
}
