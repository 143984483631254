import React from "react";

export default function PlacehoderCards() {
   return (
      <>
         <div className="row">
            <div className="col-sm-6 col-md-3 col-lg-3">
               <div className="card placeholder-glow">
                  <div className="ratio ratio-21x9 card-img-top placeholder" />
                  <div className="card-body">
                     <div className="placeholder col-9 mb-3" />
                     <div className="placeholder placeholder-xs col-10" />
                     <div className="placeholder placeholder-xs col-11" />
                     <div className="mt-3">
                        <a
                           href="#"
                           tabIndex={-1}
                           className="btn btn-primary disabled placeholder col-4"
                           aria-hidden="true"
                        />
                     </div>
                  </div>
               </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3">
               <div className="card placeholder-glow">
                  <div className="ratio ratio-21x9 card-img-top placeholder" />
                  <div className="card-body">
                     <div className="placeholder col-9 mb-3" />
                     <div className="placeholder placeholder-xs col-10" />
                     <div className="placeholder placeholder-xs col-11" />
                     <div className="mt-3">
                        <a
                           href="#"
                           tabIndex={-1}
                           className="btn btn-primary disabled placeholder col-4"
                           aria-hidden="true"
                        />
                     </div>
                  </div>
               </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3">
               <div className="card placeholder-glow">
                  <div className="ratio ratio-21x9 card-img-top placeholder" />
                  <div className="card-body">
                     <div className="placeholder col-9 mb-3" />
                     <div className="placeholder placeholder-xs col-10" />
                     <div className="placeholder placeholder-xs col-11" />
                     <div className="mt-3">
                        <a
                           href="#"
                           tabIndex={-1}
                           className="btn btn-primary disabled placeholder col-4"
                           aria-hidden="true"
                        />
                     </div>
                  </div>
               </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3">
               <div className="card placeholder-glow">
                  <div className="ratio ratio-21x9 card-img-top placeholder" />
                  <div className="card-body">
                     <div className="placeholder col-9 mb-3" />
                     <div className="placeholder placeholder-xs col-10" />
                     <div className="placeholder placeholder-xs col-11" />
                     <div className="mt-3">
                        <a
                           href="#"
                           tabIndex={-1}
                           className="btn btn-primary disabled placeholder col-4"
                           aria-hidden="true"
                        />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}
