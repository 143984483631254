import React, { useState } from "react";
import PropTypes from "prop-types";
import imgDevices from "../../Assets/img/img/pngegg.png";
import iconConnect from "../../Assets/img/img/wifi (1).svg";
import iconDisconnect from "../../Assets/img/img/disconnect.svg";
import protectsvg from "../../Assets/img/img/Protect.svg";
import passwordsvg from "../../Assets/img/img/Password.svg";
import { useNavigate } from "react-router-dom";
import Pagination from "../Pagination/Pagination";
import useUrl from "../../Services/Hooks/useUrl";
import iconEmpty from "../../Assets/img/img/no-results-bg.2d2c6ee3.png";

function DevicesLists(props) {
   const navigate = useNavigate();
   const url = useUrl();
   const [currentPage, setCurrentPage] = useState(1);
   const { devicesLists } = props;
   const itemPerPage = 4;
   const propsCurrentPage = {
      currentPage,
      setCurrentPage,
   };

   const navigateDeviceDetail = (id) => {
      navigate(url.getDeviceDetail(id));
   };

   const renderDevicesList = () => {
      let start = (currentPage - 1) * itemPerPage;
      let end = currentPage * itemPerPage;
      let jsx = [];
      for (let index in devicesLists) {
         if (index >= start && index < end) {
            const { deviceAlias, deviceType, osType, _id, deviceId, isOnline } =
               devicesLists[index];
            const item = (
               <div key={_id} className="col-sm-6 col-lg-3 mb-3">
                  <div
                     onClick={(e) => {
                        e.preventDefault();
                        navigateDeviceDetail(deviceId);
                     }}
                     className="card card-hover"
                  >
                     <div className="card-body">
                        <div className="card-header">
                           <div className="u_device">
                              <div className="u_device-img">
                                 <img src={imgDevices} alt="device" />
                                 <div className="u_device-icon">
                                    <img
                                       src={
                                          isOnline
                                             ? iconConnect
                                             : iconDisconnect
                                       }
                                       alt="icon"
                                    />
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div
                           onClick={(e) => {
                              e.preventDefault();
                              navigateDeviceDetail(deviceId);
                           }}
                           className="card-content"
                        >
                           <h2 className="device_name">
                              {deviceAlias}&nbsp;
                              <span>({deviceType})</span>
                           </h2>
                           <span
                              onClick={(e) => {
                                 e.preventDefault();
                                 navigateDeviceDetail(deviceId);
                              }}
                              className="material-symbols-outlined arrow-icon"
                           >
                              arrow_forward_ios
                           </span>
                        </div>
                        {/* <div className="device_decription mb-1">
                              <p className="info">
                                 Lần kết nối gần nhất: <span>06/04/2023</span>,{" "}
                                 <span>08:30 AM</span>
                              </p>
                           </div> */}
                        <div className="device__icon mt-4 mb-3">
                           <img
                              src={protectsvg}
                              className="me-2"
                              alt="blocked"
                           />
                           <img src={passwordsvg} alt="blocked" />
                        </div>
                     </div>
                  </div>
               </div>
            );
            jsx.push(item);
         }
      }
      if (devicesLists.length <= 0) {
         const empty = (
            <div key={'2'} className="row">
               <div className="col-lg-12">
                  <div className="no-devices">
                     <img
                        className="no-device_icon mb-2"
                        src={iconEmpty}
                        alt=""
                     />
                     <p className="no-device_decription text-center">
                        No results found.
                     </p>
                  </div>
               </div>
            </div>
         );
         jsx.push(empty);
      }
      return jsx;
   };
   return (
      <>
         {renderDevicesList()}
         <Pagination
            devicesLists={devicesLists}
            propsCurrentPage={propsCurrentPage}
         />
      </>
   );
}

DevicesLists.propTypes = {};

export default DevicesLists;
