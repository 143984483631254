import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import "./Devices.scss";
import "@fortawesome/fontawesome-free";
import Decription from "../Decription/Decription";
import clsx from "clsx";
import PlacehoderCards from "../../Components/Placehoder/PlacehoderCards";
import { useNavigate } from "react-router-dom";
import DevicesLists from "../../Components/DevicesLists/DevicesLists";
import DevicesListsDetail from "../../Components/DevicesLists/DevicesListsDetail";
import useClient from "../../Services/Hooks/useClient";
import { encrypt_data, decrypt_data } from "../../Services/Helpers/Helpers";
import iconNoDevice from "../../Assets/img/img/clipart1932738.png";
import { DebounceInput } from "react-debounce-input";

function Devices(props) {
   const [isLoading, setLoading] = useState(true);
   const [search, setSearch] = useState(false);
   const [modal, setModal] = useState(false);
   const [isCheckedManager, setCheckedManager] = useState(true);
   const [isCheckedDetail, setCheckedDetail] = useState(true);
   const [devicesLists, setDevicesLists] = useState([]);
   const [token, setToken] = useState("");
   const modalRef = useRef(null);
   const inputRef = useRef(null);
   const navigate = useNavigate();
   const client = useClient();
   let isMouseOver = false;

   useEffect(() => {
      if (sessionStorage.getItem("login")) {
         let dataLogin = sessionStorage.getItem("login");
         dataLogin = JSON.parse(dataLogin);
         const { token } = dataLogin;
         setToken(token);
         getDevicesLists(token);
      }
   }, []);

   useEffect(() => {
      if (modalRef.current) {
         setTimeout(() => {
            modalRef.current.classList.toggle("show");
         }, 10);
      }
   }, [modal]);

   const handleShowDetailDevices = (e) => {
      e.preventDefault();
      if (e.target.innerText === "Detail") {
         setCheckedDetail(false);
      } else {
         setCheckedDetail(true);
      }
   };

   const showSearch = (e, pathName) => {
      e.preventDefault();
      if (e.target.innerText === "Search" || pathName === "search") {
         setSearch(true);
      } else {
         setSearch(false);
         if (devicesLists.length <= 0) {
            getDevicesLists(token);
         }
      }
   };

   const handeShowModal = (e) => {
      e.preventDefault();
      setModal(true);
      // document.body.style.overflow = "hidden";
      // document.body.style.paddingRight = "16px";
   };

   const handeHideModal = (e) => {
      e.preventDefault();
      if (!isMouseOver) {
         modalRef.current?.classList.toggle("show");
         setTimeout(() => {
            setModal(false);
         }, 50);
         setTimeout(() => {
            document.body.style = "none";
         }, 100);
      }
   };

   const getDevicesSearch = async (keywords, token) => {
      const res = await client.get(
         client.devices + `?name=${keywords}`,
         {},
         token
      );
      if (res.response.ok) {
         res.data = decrypt_data(res.data);
         const data = res.data;
         setDevicesLists(data.data);
      } else {
         console.log("false");
      }
   };

   const handleSearch = (e) => {
      getDevicesSearch(e.target.value, token);
   };

   const getDevicesLists = async (token) => {
      const res = await client.get(client.devices, {}, token);
      if (res.response.ok) {
         const data = decrypt_data(res.data);
         setLoading(false);
         setDevicesLists(data.data);
      }
   };

   return (
      <>
         <div className="row g-2 align-items-center mb-3 mt-3 mx-2">
            <div className="col">
               <h1 className="page-title">Devices</h1>
            </div>
            <div className="col-auto ms-auto d-none-sm">
               <div className="btn-list">
                  {search ? (
                     <div className="my-2 my-md-0 flex-grow-1 flex-md-grow-0 order-first search d-print-none">
                        <form>
                           <div className="input-icon">
                              <span className="input-icon-addon">
                                 <i className="ti ti-search icon"></i>
                              </span>
                              <DebounceInput
                                 type="text"
                                 minLength={2}
                                 debounceTimeout={400}
                                 onChange={handleSearch}
                                 className="form-control"
                                 placeholder="Search…"
                              />
                           </div>
                        </form>
                        <div className="icon-close">
                           <i className="ti ti-x icon" onClick={showSearch}></i>
                        </div>
                     </div>
                  ) : (
                     <span onClick={showSearch} className="d-sm-inline">
                        <a href="#" className="btn">
                           Search
                        </a>
                     </span>
                  )}
                  <a
                     href="#"
                     className="btn btn-primary d-sm-inline-block"
                     onClick={handeShowModal}
                  >
                     <i className="ti ti-plus icon"></i>
                     Add device
                  </a>
               </div>
            </div>
            <div className="col d-flex hidden-elm">
               <button
                  href="#"
                  className="btn btn-primary ms-auto"
                  onClick={handeShowModal}
               >
                  <i className="ti ti-plus icon"></i>
                  Add device
               </button>
            </div>
         </div>
         <div className="row row-deck row-cards">
            <div className="devices__app mb-3">
               <div className={clsx("conduct-devices", search && "d-none-sm")}>
                  <div
                     className={clsx(
                        "devices--total isHover",
                        !isCheckedManager ? "checked" : null
                     )}
                  >
                     All <span>({devicesLists.length})</span>
                  </div>
                  <div className="slash"></div>
                  <div
                     className={clsx(
                        "devices--managed isHover",
                        isCheckedManager ? "checked" : null
                     )}
                  >
                     Managed devices <span>({devicesLists.length})</span>
                  </div>
               </div>
               <div className="status-devides hidden-elm">
                  <div
                     onClick={handleShowDetailDevices}
                     className={clsx(
                        "succinct isHover",
                        isCheckedDetail ? "checked" : null
                     )}
                  >
                     Compact
                  </div>
                  <div className="slash"></div>
                  <div
                     onClick={handleShowDetailDevices}
                     className={clsx(
                        "detail isHover",
                        !isCheckedDetail ? "checked" : null
                     )}
                  >
                     Detail
                  </div>
               </div>
               {search ? (
                  <div className="my-2 my-md-0 search hidden-elm">
                     <form>
                        <div className="input-icon">
                           <span className="input-icon-addon">
                              <i className="ti ti-search icon"></i>
                           </span>
                           <DebounceInput
                              type="text"
                              minLength={2}
                              debounceTimeout={400}
                              onChange={handleSearch}
                              className="form-control"
                              placeholder="Search…"
                           />
                        </div>
                     </form>
                     <div className="icon-close">
                        <i className="ti ti-x icon" onClick={showSearch}></i>
                     </div>
                  </div>
               ) : (
                  <i
                     className="ti ti-search icon hidden-elm"
                     onClick={(e) => {
                        showSearch(e, "search");
                     }}
                  ></i>
               )}
            </div>
            {isLoading ? (
               <PlacehoderCards />
            ) : !isCheckedDetail ? (
               <DevicesListsDetail devicesLists={devicesLists} />
            ) : (
               <DevicesLists devicesLists={devicesLists} />
            )}
         </div>
         {/* <div className="row">
            <div className="col-lg-12">
               <div className="no-devices">
                  <img className="no-device_icon mb-2" src={iconNoDevice} alt="" />
                  <p className="no-device_decription">
                     You have no devices connected to your account.
                  </p>
               </div>
            </div>
         </div> */}
         {/* <Decription /> */}
         {modal ? (
            <>
               <div
                  className="modal modal-blur fade"
                  ref={modalRef}
                  id="modal-report"
                  role="dialog"
                  onClick={handeHideModal}
               >
                  <div className="modal-dialog modal-lg">
                     <div
                        className="modal-content"
                        onMouseOver={() => {
                           isMouseOver = true;
                        }}
                        onMouseOut={() => {
                           isMouseOver = false;
                        }}
                     >
                        <div className="modal-header">
                           <h1 className="modal-title text-center">
                              Connect subscription to download apps
                           </h1>
                           <i
                              className="ti ti-x icon"
                              onClick={(e) => {
                                 e.preventDefault();
                                 modalRef.current?.classList.remove("show");
                                 setTimeout(() => {
                                    setModal(false);
                                 }, 50);
                                 setTimeout(() => {
                                    document.body.style = "none";
                                 }, 100);
                              }}
                           ></i>
                        </div>
                        <div className="modal-body modal-pd">
                           <form className="form_add">
                              <h2 className="modal-subtitle">
                                 Add activation code
                              </h2>
                              <p className="modal-decription">
                                 Save the code in your account to easily
                                 download apps and link devices to your
                                 subscription.
                              </p>
                              <input
                                 type="text"
                                 className="form-control text-center mb-3"
                                 placeholder="XXXXX-XXXXX-XXXXX-XXXXX"
                              />
                              <p className="modal-sub_decription">
                                 Your subscription term can start immediately
                                 after an activation code has been added!
                                 <a href="#" target="_blank">
                                    Details.
                                 </a>
                              </p>
                              <button className="btn btn-primary disabled mb-3">
                                 Add
                              </button>
                              <p className="modal-help1">
                                 No activation code?
                                 <a href="#" target="_blank">
                                    Purchase subscription.
                                 </a>
                              </p>
                              <p className="modal-help2">
                                 Don't know what an activation code is?
                                 <a href="#" target="_blank">
                                    Click here.
                                 </a>
                              </p>
                           </form>
                        </div>
                        <div className="modal-footer">
                           <h2 className="modal-title">
                              Want to see what else we have for you?
                           </h2>
                           <a href="#" target="_blank">
                              Go to Basprotect store and get a new subscription.
                           </a>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="modal-backdrop fade show"></div>
            </>
         ) : null}
      </>
   );
}

Devices.propTypes = {};

export default Devices;
