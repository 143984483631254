import React, { useRef, useEffect } from "react";

export default function ModalWipeData(props) {
   const btnRef = useRef();
   const modalRef = useRef();
   const { deviceId, token, setShowModal } = props;
   let isMouseOver = false;

   useEffect(() => {
      setTimeout(() => {
         modalRef.current.classList.add("show");
      }, 50);
   }, []);

   const handeHideModal = (e) => {
      e.preventDefault();
      if (!isMouseOver) {
         modalRef.current?.classList.remove("show");
         setTimeout(() => {
            setShowModal(false);
         }, 50);
         setTimeout(() => {
            document.body.style = "none";
         }, 100);
      }
   };
   return (
      <>
         <div
            className="modal modal-blur fade"
            ref={modalRef}
            id="modal-simple"
            onClick={handeHideModal}
         >
            <div className="modal-dialog modal-dialog-centered" role="document">
               <div
                  className="modal-content"
                  onMouseOver={() => {
                     isMouseOver = true;
                  }}
                  onMouseOut={() => {
                     isMouseOver = false;
                  }}
               >
                  <div className="modal-header">
                     <h5 className="modal-title">Wipe Data</h5>
                     <button
                        type="button"
                        className="btn-close"
                        onClick={(e) => {
                           e.preventDefault();
                           modalRef.current?.classList.remove("show");
                           setTimeout(() => {
                              setShowModal(false);
                           }, 50);
                           setTimeout(() => {
                              document.body.style = "none";
                           }, 100);
                        }}
                     />
                  </div>
                  <div className="modal-body">
                     <div className="modal_head">
                        <span className="material-symbols-outlined icon_modal">
                           <span className="material-symbols-outlined">
                              other_admission
                           </span>
                        </span>
                        <div className="desc_modal">
                           Wipe data from your device to prevent personal
                           information from falling into the wrong hands.
                        </div>
                     </div>
                     <div className="modal_content">
                        <div className="about mt-3">
                           <input className="me-3" type="radio" />
                           <div className="about_box">
                              <p className="title">
                                 Wipe all data from your device
                              </p>
                              <p className="content">
                                 Reset the device to its factory settings. After
                                 applying this command, your device will not be
                                 able to receive any further commands.
                              </p>
                           </div>
                        </div>
                        <div className="warning_text text-center mt-3">
                           Warning! After pressing the Wipe data button, your
                           device will be reset to its factory settings.
                        </div>
                     </div>
                  </div>
                  <div className="modal-footer">
                     <button
                        onClick={(e) => {
                           e.preventDefault();
                           if (btnRef.current) {
                              setTimeout(() => {
                                 btnRef.current.classList.toggle("show");
                              }, 100);
                           }
                        }}
                        type="button"
                        className="btn btn-danger btn-confirm"
                     >
                        Wipe data
                     </button>
                     <div ref={btnRef} className="confirm-form">
                        <label className="form-label mb-2">
                           Please confirm:
                        </label>
                        <button
                           type="button"
                           className="btn btn-danger w-100 mb-2"
                        >
                           Confirm
                        </button>
                        <button
                           onClick={() => {
                              setTimeout(() => {
                                 btnRef.current.classList.toggle("show");
                              }, 10);
                           }}
                           type="button"
                           className="btn btn-primary w-100"
                        >
                           Cancel
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="modal-backdrop fade show"></div>
      </>
   );
}
