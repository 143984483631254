import React, { useRef, useEffect, useState } from "react";
import useClient from "../../Services/Hooks/useClient";
import { encrypt_data, decrypt_data } from "../../Services/Helpers/Helpers";

export default function ModalAlarm(props) {
   const btnRef = useRef();
   const modalRef = useRef();
   const client = useClient();
   const [message, setMessage] = useState("");
   const { deviceId, token, setShowModal } = props;
   let isMouseOver = false;

   useEffect(() => {
      setTimeout(() => {
         modalRef.current.classList.add("show");
      }, 50);
   }, []);

   const handeHideModal = (e) => {
      e.preventDefault();
      if (!isMouseOver) {
         modalRef.current?.classList.remove("show");
         setTimeout(() => {
            setShowModal(false);
         }, 50);
         setTimeout(() => {
            document.body.style = "none";
         }, 100);
      }
   };

   const handleChangeValue = (e) => {
      e.preventDefault();
      const message = e.target.value;
      if (message !== "") {
         setMessage(message);
      }
   };

   const handleAlarmDevice = async (id, data, token) => {
      const res = await client.post(
         client.devices + `/${id}` + client.alarm,
         data,
         {},
         token
      );
      if (res.response.ok) {
         res.data = decrypt_data(res.data);
         const data = res.data;
         window.confirm("successful alarm!");
      }
   };

   return (
      <>
         <div
            className="modal modal-blur fade"
            ref={modalRef}
            id="modal-simple"
            onClick={handeHideModal}
         >
            <div className="modal-dialog modal-dialog-centered" role="document">
               <div
                  className="modal-content"
                  onMouseOver={() => {
                     isMouseOver = true;
                  }}
                  onMouseOut={() => {
                     isMouseOver = false;
                  }}
               >
                  <div className="modal-header">
                     <h5 className="modal-title">Alarm</h5>
                     <button
                        type="button"
                        className="btn-close"
                        onClick={(e) => {
                           e.preventDefault();
                           modalRef.current?.classList.remove("show");
                           setTimeout(() => {
                              setShowModal(false);
                           }, 50);
                           setTimeout(() => {
                              document.body.style = "none";
                           }, 100);
                        }}
                     />
                  </div>
                  <div className="modal-body">
                     <div className="modal_head">
                        <span className="material-symbols-outlined icon_modal">
                           <span className="material-symbols-outlined">
                              notifications
                           </span>
                        </span>
                        <div className="desc_modal">
                           Turn on alarm and lock the device with a screen
                           message (optional).
                        </div>
                     </div>
                     <div className="modal_content">
                        <form>
                           <div className="mt-4">
                              <label className="form-label">
                                 Screen massage:
                              </label>
                              <textarea
                                 type="text"
                                 className="form-control mb-1"
                                 placeholder="Message..."
                                 onChange={handleChangeValue}
                              />
                              <span>
                                 Message to appear on the device screen
                                 (optional).
                              </span>
                           </div>
                        </form>
                        <div className="warning_text text-center mt-3">
                           Warning! After pressing the Enable alarm button, your
                           device will be locked.
                        </div>
                     </div>
                  </div>
                  <div className="modal-footer">
                     <button
                        onClick={(e) => {
                           e.preventDefault();
                           if (btnRef.current) {
                              setTimeout(() => {
                                 btnRef.current.classList.toggle("show");
                              }, 100);
                           }
                        }}
                        type="button"
                        className="btn btn-danger btn-confirm"
                     >
                        Alarm
                     </button>
                     <div ref={btnRef} className="confirm-form">
                        <label className="form-label mb-2">
                           Please confirm:
                        </label>
                        <button
                           type="button"
                           className="btn btn-danger w-100 mb-2"
                           onClick={(e) => {
                              e.preventDefault();
                              const data = {
                                 message: message,
                              };
                              handleAlarmDevice(
                                 deviceId,
                                 { data: encrypt_data(data) },
                                 token
                              );
                           }}
                        >
                           Confirm
                        </button>
                        <button
                           onClick={() => {
                              setTimeout(() => {
                                 btnRef.current.classList.toggle("show");
                              }, 10);
                           }}
                           type="button"
                           className="btn btn-primary w-100"
                        >
                           Cancel
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="modal-backdrop fade show"></div>
      </>
   );
}
