import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./Auth.scss";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import iconError from "../../Assets/img/img/error.png";
import clsx from "clsx";
import useClient from "../../Services/Hooks/useClient";
import { encrypt_data, decrypt_data } from "../../Services/Helpers/Helpers";
import BtnLoading from "../../Components/BtnLoading/BtnLoading";
import iconFireword from "../../Assets/img/img/fireworks.png";

function NewPassword(props) {
   const [isError, setError] = useState({
      status: false,
      message: "",
   });
   const [isLoading, setLoading] = useState(false);
   const [isSuccess, setSuccess] = useState({
      status: false,
      message: "",
   });
   const [btnStatus, setBtnStatus] = useState(true);
   const [validPassword, setValidPassword] = useState(true);
   const [validConfirmPassword, setValidConfirmPassword] = useState(true);
   const [showPassword, setShowPassword] = useState(false);
   const [form, setForm] = useState({
      password: "",
      confirmPassword: "",
   });
   const [token, setToken] = useState("");
   const client = useClient();
   const navigate = useNavigate();
   const [searchParams, setSearchParams] = useSearchParams();

   useEffect(() => {
      getToken();
   }, []);

   const getToken = () => {
      const token = searchParams.get("token");
      if (token === null) {
         setError({
            status: true,
         });
      } else {
         setError({
            status: false,
         });
         setToken(token);
      }
   };

   const handleChecked = (e) => {
      if (e.target.checked) {
         setShowPassword(true);
      } else {
         setShowPassword(false);
      }
   };

   const handleChangeValue = (e) => {
      const data = { ...form };
      data[e.target.name] = e.target.value;
      setForm(data);

      if (data.password.length < 6 && data.password !== "") {
         setValidPassword(false);
      } else {
         setValidPassword(true);
      }

      if (
         data.confirmPassword.length < 6 &&
         data.confirmPassword !== data.password &&
         data.confirmPassword !== ""
      ) {
         setValidConfirmPassword(false);
      } else {
         setValidConfirmPassword(true);
      }

      if (data.password.length && data.confirmPassword.length < 5) {
         setBtnStatus(true);
      } else {
         setBtnStatus(false);
      }
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      const data = {
         token: token,
         password: form.confirmPassword,
      };
      postChangePassword({ data: encrypt_data(data) });
   };

   const postChangePassword = async (data) => {
      setLoading(true);
      const res = await client.post(client.change_password, data);
      if (res.response.ok) {
         const data = decrypt_data(res.data);
         console.log(data);
         if (data.status) {
            setSuccess({
               status: true,
               message: data.message,
            });
            // navigate("/verified");
         }
      } else {
         setLoading(false);
         const data = decrypt_data(res.data);
         setError({
            status: true,
            message: data.message,
         });
      }
   };

   return (
      <>
         {!isSuccess.status ? (
            <>
               {isError.status ? (
                  <div className="card card-md">
                     <div className="card-body">
                        <h2 className="card-title mb-4">Error !</h2>
                        <div className="card-icon mb-4">
                           <img src={iconError} alt="" />
                        </div>
                        <div
                           className="alert alert-danger alert-dismissible"
                           role="alert"
                        >
                           <div className="d-flex box">
                              <i className="ti ti-exclamation-circle icon alert-icon"></i>
                              <h4 className="alert-title">
                                 Failure! Please try again.
                              </h4>
                           </div>
                        </div>

                        <div className="form-footer">
                           <Link to="/" className="btn btn-danger w-100">
                              Back
                           </Link>
                        </div>
                     </div>
                  </div>
               ) : (
                  <form className="card card-md" onSubmit={handleSubmit}>
                     <div className="card-body">
                        <h2 className="card-title text-center mb-4">
                           Create new password
                        </h2>
                        <div className="mb-3">
                           <label className="form-label">New password</label>
                           <input
                              type={showPassword ? "text" : "password"}
                              name="password"
                              className={clsx(
                                 "form-control",
                                 !validPassword
                                    ? "is-invalid"
                                    : form.password !== ""
                                    ? "is-valid"
                                    : null
                              )}
                              placeholder="New password"
                              onChange={handleChangeValue}
                           />
                        </div>
                        <div className="">
                           <label className="form-label">
                              Confirm password
                           </label>
                           <input
                              type={showPassword ? "text" : "password"}
                              name="confirmPassword"
                              className={clsx(
                                 "form-control",
                                 !validConfirmPassword
                                    ? "is-invalid"
                                    : form.confirmPassword === ""
                                    ? null
                                    : form.confirmPassword === form.password
                                    ? "is-valid"
                                    : "is-invalid"
                              )}
                              placeholder="Confirm password"
                              onChange={handleChangeValue}
                           />
                        </div>
                        <div className="help-form mt-2">
                           <small>* At least 6 characters *</small>
                           <div>
                              <div className="form-check">
                                 <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onChange={handleChecked}
                                 />
                                 <span className="form-check-label">
                                    Show password.
                                 </span>
                              </div>
                           </div>
                        </div>
                        <div className="form-footer">
                           {isLoading ? (
                              <BtnLoading />
                           ) : (
                              <button
                                 type="submit"
                                 className={clsx(
                                    "btn btn-primary w-100",
                                    btnStatus && "disabled"
                                 )}
                              >
                                 Create new password
                              </button>
                           )}
                        </div>
                     </div>
                  </form>
               )}
               <div className="text-center text-muted mt-3">
                  Already have account? <Link to={"/"}>Sign in</Link>
               </div>
            </>
         ) : (
            <>
               <div className="card card-md">
                  <div className="card-body">
                     <h2 className="card-title mb-4">Done !</h2>
                     <div className="card-icon mb-4">
                        <img src={iconFireword} alt="" />
                     </div>
                     <div
                        className="alert alert-success alert-dismissible"
                        role="alert"
                     >
                        <div className="d-flex">
                           <div>
                              <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 className="icon alert-icon"
                                 width={24}
                                 height={24}
                                 viewBox="0 0 24 24"
                                 strokeWidth={2}
                                 stroke="currentColor"
                                 fill="none"
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              >
                                 <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                 />
                                 <path d="M5 12l5 5l10 -10" />
                              </svg>
                           </div>
                           <div>
                              <h4 className="alert-title">
                                 {isSuccess.message}
                              </h4>
                           </div>
                        </div>
                     </div>

                     <div className="form-footer">
                        <Link to="/" className="btn btn-success w-100">
                           Back to login
                        </Link>
                     </div>
                  </div>
               </div>
            </>
         )}
      </>
   );
}

NewPassword.propTypes = {};

export default NewPassword;
