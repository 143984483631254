import React, { useEffect, useState } from "react";
import Main from "./Layouts/Main/Main";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Authenticator from "./Pages/Auth/Authenticator";
import { authSelector } from "./Pages/Auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { getUser } from "./Pages/Auth/authSlice";
import LoadingPage from "./Components/LoadingPage/LoadingPage";

function App() {
   const { isAuth } = useSelector(authSelector);
   const [loadingPage, setLoadingPage] = useState(true);
   const dispatch = useDispatch();

   useEffect(() => {
      getIsAuth();
      dispatch(getUser());
      setTimeout(() => {
         setLoadingPage(false);
      }, 100);
   }, [isAuth]);

   const getIsAuth = () => {
      let initisAuth = false;
      if (sessionStorage.getItem("login")) {
         let dataLogin = sessionStorage.getItem("login");
         dataLogin = JSON.parse(dataLogin);
         const { isAuth } = dataLogin;
         initisAuth = isAuth;
      }
      return initisAuth;
   };

   return (
      <>
         {loadingPage ? (
            <LoadingPage />
         ) : getIsAuth() ? (
            <Main />
         ) : (
            <Authenticator />
         )}
      </>
   );
}

export default App;
