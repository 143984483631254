import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const navbars = [
   {
      id: 1,
      name: "Devices",
      iconName: "",
      path: "/",
   },
   {
      id: 2,
      name: "Subcriptions",
      iconName: "",
      path: "/subcriptions",
   },
   {
      id: 3,
      name: "Secure Connection",
      iconName: "",
      path: "/secure-connection",
   },
];

function Navigation(props) {
   return (
      <header className="navbar-expand-md">
         <div className="collapse navbar-collapse" id="navbar-menu">
            <div className="navbar navbar-light">
               <div className="container-xl">
                  <ul className="navbar-nav">
                     {navbars.map(({ id, name, path }) => {
                        return (
                           <li key={id} className="nav-item nav-item-hover">
                              <NavLink className="nav-link" to={path}>
                                 <span className="nav-link-title">{name}</span>
                              </NavLink>
                           </li>
                        );
                     })}
                  </ul>

               </div>
            </div>
         </div>
      </header>
   );
}

Navigation.propTypes = {};

export default Navigation;
