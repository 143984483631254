import React, { useEffect, useRef, useState } from "react";
import useClient from "../../Services/Hooks/useClient";
import { encrypt_data, decrypt_data } from "../../Services/Helpers/Helpers";

export default function ModalLockLocate(props) {
   const btnRef = useRef();
   const modalRef = useRef();
   const client = useClient();
   const [message, setMessage] = useState("");
   const { deviceId, token, setShowModal } = props;

   let isMouseOver = false;

   useEffect(() => {
      setTimeout(() => {
         modalRef.current.classList.add("show");
      }, 50);
   }, []);

   const hidden = () => {
      modalRef.current?.classList.remove("show");
      setTimeout(() => {
         setShowModal(false);
      }, 50);
      setTimeout(() => {
         document.body.style = "none";
      }, 100);
   };

   const handeHideModal = async (e) => {
      e.preventDefault();
      if (!isMouseOver) {
         hidden();
      }
   };

   const handleChangeValue = (e) => {
      e.preventDefault();
      const message = e.target.value;
      if (message !== "") {
         setMessage(message);
      }
   };

   const handleLockDevice = async (id, data, token) => {
      const res = await client.post(
         client.devices + `/${id}` + client.lock,
         data,
         {},
         token
      );
      if (res.response.ok) {
         window.confirm("successful lock!")
         // res.data = decrypt_data(res.data);
         // const data = res.data;
      }
   };

   return (
      <>
         <div
            className="modal modal-blur fade"
            ref={modalRef}
            id="modal-simple"
            onClick={handeHideModal}
         >
            <div className="modal-dialog modal-dialog-centered" role="document">
               <div
                  className="modal-content"
                  onMouseOver={() => {
                     isMouseOver = true;
                  }}
                  onMouseOut={() => {
                     isMouseOver = false;
                  }}
               >
                  <div className="modal-header">
                     <h5 className="modal-title">Lock & Locate</h5>
                     <button
                        type="button"
                        className="btn-close"
                        onClick={(e) => {
                           e.preventDefault();
                           hidden();
                        }}
                     />
                  </div>
                  <div className="modal-body">
                     <div className="modal_head">
                        <span className="material-symbols-outlined icon_modal">
                           location_on
                        </span>
                        <div className="desc_location">
                           Lock your device, locate it on the map, and receive
                           its coordinates by email.
                        </div>
                     </div>
                     <div className="modal_content">
                        <form>
                           <div className="mt-4">
                              <label className="form-label">
                                 Screen massage:
                              </label>
                              <textarea
                                 type="text"
                                 className="form-control mb-1"
                                 placeholder="Message..."
                                 onChange={handleChangeValue}
                              />
                              <span>
                                 Message to appear on the device screen
                                 (optional).
                              </span>
                           </div>
                        </form>
                        <div className="warning_text text-center mt-3">
                           Warning! After pressing the Lock & Locate button,
                           your device will be locked.
                        </div>
                     </div>
                  </div>
                  <div className="modal-footer">
                     <button
                        onClick={(e) => {
                           e.preventDefault();
                           if (btnRef.current) {
                              setTimeout(() => {
                                 btnRef.current.classList.toggle("show");
                              }, 100);
                           }
                        }}
                        type="button"
                        className="btn btn-danger btn-confirm"
                     >
                        Lock & Locate
                     </button>
                     <div ref={btnRef} className="confirm-form">
                        <label className="form-label mb-2">
                           Please confirm:
                        </label>
                        <button
                           type="button"
                           className="btn btn-danger w-100 mb-2"
                           onClick={(e) => {
                              e.preventDefault();
                              const data = {
                                 message: message,
                              };
                              handleLockDevice(
                                 deviceId,
                                 { data: encrypt_data(data) },
                                 token
                              );
                              hidden();
                           }}
                        >
                           Confirm
                        </button>
                        <button
                           onClick={() => {
                              setTimeout(() => {
                                 btnRef.current.classList.toggle("show");
                              }, 10);
                           }}
                           type="button"
                           className="btn btn-primary w-100"
                        >
                           Cancel
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="modal-backdrop fade show"></div>
      </>
   );
}
