import React, { useEffect, useState } from "react";
import "./Pagination.scss";
import clsx from "clsx";

export default function Pagination(props) {
   const { devicesLists, propsCurrentPage } = props;
   const { currentPage, setCurrentPage } = propsCurrentPage;
   const itemPerPage = 4;

   let totalPage = Math.ceil(devicesLists.length / itemPerPage);

   const renderListPages = () => {
      let jsx = [];
      for (let i = 1; i <= totalPage; i++) {
         const item = (
            <li
               key={i}
               onClick={handleChangePage}
               className={`page-item${currentPage == i ? " active" : ""}`}
            >
               <a className="page-link" href="#">
                  {i}
               </a>
            </li>
         );
         jsx.push(item);
      }
      return jsx;
   };

   const handleChangePage = (e) => {
      e.preventDefault();
      if (e.target.innerText !== undefined) {
         setCurrentPage(parseFloat(e.target.innerText));
      }
   };

   const handlePreviousPage = (e) => {
      e.preventDefault();
      setCurrentPage(parseFloat(currentPage - 1));
      if (currentPage <= 1) {
         setCurrentPage(parseFloat(currentPage));
      }
   };
   const handleNextPage = (e) => {
      e.preventDefault();
      setCurrentPage(parseFloat(currentPage + 1));
      if (currentPage >= totalPage) {
         setCurrentPage(parseFloat(totalPage));
      }
   };

   return (
      <div
         className={clsx(
            "container-xl mt-3",
            devicesLists.length <= 0 && "d-none"
         )}
      >
         <div className="row row-cards">
            <div className="col-12">
               <div className="card">
                  <div className="card-body">
                     <ul className="pagination ">
                        <li
                           onClick={handlePreviousPage}
                           className={clsx(
                              "page-item",
                              currentPage <= 1 ? "disabled" : null
                           )}
                        >
                           <a className="page-link" href="#">
                              <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 className="icon"
                                 width={24}
                                 height={24}
                                 viewBox="0 0 24 24"
                                 strokeWidth={2}
                                 stroke="currentColor"
                                 fill="none"
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              >
                                 <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                 />
                                 <path d="M15 6l-6 6l6 6" />
                              </svg>
                              prev
                           </a>
                        </li>
                        {renderListPages()}
                        <li
                           onClick={handleNextPage}
                           className={clsx(
                              "page-item",
                              currentPage >= totalPage ? "disabled" : null
                           )}
                        >
                           <a className="page-link" href="#">
                              next{" "}
                              <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 className="icon"
                                 width={24}
                                 height={24}
                                 viewBox="0 0 24 24"
                                 strokeWidth={2}
                                 stroke="currentColor"
                                 fill="none"
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              >
                                 <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                 />
                                 <path d="M9 6l6 6l-6 6" />
                              </svg>
                           </a>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
