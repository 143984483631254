import React from "react";

export default function ResponsePendding() {
   return (
      <div className="notify">
         <div className="spinner-border text-yellow me-3" role="status"></div>
         <div className="notify_decription">
            <strong>Sent</strong>
            <p>Waiting for response...</p>
         </div>
      </div>
   );
}
