import React from "react";
import { Routes, Route } from "react-router-dom";
import Error404 from "../../Pages/Errors/Error404";
import { authRoutes } from "../../Routes/authRoutes";

export default function AuthRouteCore() {
   return (
      <Routes>
         {authRoutes}
         <Route path="*" element={<Error404 />} />
      </Routes>
   );
}
