import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./Header.scss";
import Navigation from "./Navigation";
import { authSelector, getUser } from "../../Pages/Auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import logo from "../../Assets/img/img/Frame 2864153.jpg";
import { Link, useNavigate } from "react-router-dom";

function Header(props) {
   const { userLogin } = useSelector(authSelector);
   const [showNotifications, setShowNotifications] = useState(false);
   const [showDropdownUser, setShowDropdownUser] = useState(false);
   const navigate = useNavigate();
   const dispatch = useDispatch();
   let isMouse = false;

   const featureUsers = [
      { id: 1, name: "Profile", link: "" },
      { id: 2, name: "Settings", link: "" },
      { id: 3, name: "Logout", link: "" },
      // { id: 4, name: "Website Basprotect", link: "https://basprotect.com" },
   ];

   const navLists = [
      { id: 1, name: "EngLish", iconName: "ti ti-world" },
      { id: 2, name: "Support", iconName: "ti ti-headset" },
      { id: 3, name: "Notification", iconName: "ti ti-bell icon-bell" },
   ];

   const handleLogout = () => {
      if (sessionStorage.getItem("login")) {
         sessionStorage.removeItem("login");
         dispatch(getUser());
         navigate("/");
      }
   };

   const hideDropdown = (e) => {
      e.preventDefault();
      if (showNotifications || showDropdownUser) {
         if (!isMouse) {
            document.addEventListener("click", () => {
               console.log("ẩn");
               // console.log('run')
            });
         }
      }
   };

   const handleClick = (e, pathName) => {
      switch (pathName) {
         case "Notification":
            if (showNotifications === true) {
               setShowNotifications(false);
            } else {
               setShowNotifications(true);
               setShowDropdownUser(false);
            }
            break;
         case "user":
            if (showDropdownUser === true) {
               setShowDropdownUser(false);
            } else {
               setShowDropdownUser(true);
               setShowNotifications(false);
            }
            break;
         case "Logout":
            handleLogout();
            break;
         case "Website Basprotect":
            window.open(
               "https://basprotect.com",
               "_blank",
               "noopener,noreferrer"
            );
            break;
         default:
            break;
      }
   };

   return (
      <>
         <header className="navbar navbar-expand-md navbar-light d-print-none">
            <div className="container-xl">
               <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbar-menu"
                  aria-controls="navbar-menu"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
               >
                  <span className="navbar-toggler-icon"></span>
               </button>
               <Link to={"/"} className="logo_btech">
                  <img src={logo} alt="" width={200} className="logo_btech" />
                  {/* <span className="logo_text">Msafe</span> */}
               </Link>
               <div className="navbar-nav flex-row order-md-last">
                  <div className="d-none d-md-flex nav-list">
                     {navLists.map(({ id, name, iconName }) => {
                        return (
                           <div
                              key={id}
                              onClick={(e) => {
                                 e.preventDefault();
                                 handleClick(e, name);
                              }}
                              className="nav-link header px-0"
                           >
                              <i className={`${iconName} icon me-1`}></i>
                              <span className="name-icon">{name}</span>
                           </div>
                        );
                     })}
                     {showNotifications && (
                        <div className="dropdown-menu dropdown-menu-arrow dropdown-menu-end dropdown-menu-card dropdown-notifications show">
                           <div className="empty">
                              <div className="empty-icon">
                                 <i className="ti ti-bell-cancel icon me-1"></i>
                              </div>
                              <p className="text-muted">No notice !</p>
                              <p className="empty-subtitle text-muted">
                                 No unread notifications in this section.
                              </p>
                           </div>
                        </div>
                     )}
                  </div>
                  <div
                     className="nav-item dropdown"
                     onClick={(e) => {
                        e.preventDefault();
                        handleClick(e, "user");
                     }}
                  >
                     <a
                        href="#"
                        className="nav-link d-flex lh-1 text-reset p-0"
                     >
                        <i className="ti ti-user-circle icon icon-avatar"></i>

                        <div className="d-none d-xl-block ps-1">
                           <div className="user_email">
                              {userLogin.data?.email}
                           </div>
                        </div>
                     </a>
                     {showDropdownUser && (
                        <div
                           className={clsx(
                              "dropdown-menu dropdown-menu-end dropdown-menu-arrow dropdown-user show"
                           )}
                        >
                           {featureUsers.map(({ id, name, link }) => {
                              return (
                                 <Link
                                    to={link}
                                    onClick={(e) => {
                                       e.preventDefault();
                                       handleClick(e, name);
                                    }}
                                    key={id}
                                    className="dropdown-item"
                                 >
                                    {name}
                                 </Link>
                                 // <div className="dropdown-divider" />
                              );
                           })}
                        </div>
                     )}
                  </div>
               </div>
            </div>
         </header>
         <Navigation />
      </>
   );
}

Header.propTypes = {};

export default Header;
