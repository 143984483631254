import React from "react";

export default function BtnLoading() {
   return (
      <button className="btn btn-primary w-100" type="button">
         <span
            className="spinner-border spinner-border-sm spinner-mr"
            role="status"
            aria-hidden="true"
         ></span>
         Loading...
      </button>
   );
}
